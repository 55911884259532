import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Link,
  Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IntlContext } from "src/App";
import { createStyles, makeStyles } from "@mui/styles";
import Dashboard_details from "./dashboardDetails";
import Dashboard_summery from "./dashboardSummery";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context/Storage";
import { useEffect } from "react";
import PreLoader from "src/component/Loader";

const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumb_root: {
      "& .breadcrumb-wrap": {
        color: "#101010",
        "& li": {
          "& p": {
            color: "#101010",
            fontWeight: "400",
          },
        },
      },
    },
    root: {
      marginTop: "44px",
      "@media (max-width:1024px)": {
        marginTop: "32px",
      },
      "& .MuiAccordion-root": {
        "&:before": {
          height: "0px",
        },
        "& .MuiAccordionSummary-root": {
          padding: "0px",
          minHeight: "40px",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0px 0px 12px 0",
        },
        "&.Mui-expanded": {
          "& .summery_block": {
            borderRadius: "20px 20px 0 0",
            "& .view_test": {
              display: "none",
            },
            "& .toggle_arrow": {
              transform: "rotate(180deg)",
            },
          },
          "& .mSummery_block": {
            borderRadius: "3px 3px 0 0",
            backgroundColor: "#DEE9F1",
            "& .toggle_arrow": {
              transform: "rotate(270deg)",
            },
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px",
          },
        },
        "& .MuiAccordionDetails-root": {
          padding: "0px",
        },
      },
    },
  })
);

const Dashboard = () => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.Actionreducer?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");
  const [myOrder, setMyOrder] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChange = (panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const getMyOrder = async () => {
    let payload = {
      user: auth?.data?._id,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getMyOrder,
        payload
      );
      if (status === 200) {
        setLoader(false);
        setMyOrder(data?.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getMyOrder();
  }, []);

  return (
    <>
      {loader && <PreLoader />}
      <Box className="sub_container">
        <Box className="cart_block_wraper">
          <Box className={classes.breadcrumb_root}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              className="breadcrumb-wrap"
            >
              <Link underline="hover" color="inherit" href="/">
                {gothe_mile?.home?.home_text}
              </Link>
              <Typography>{gothe_mile?.dashboard?.breadCrumbs}</Typography>
            </Breadcrumbs>
          </Box>
          <Typography className="my_cart_title">
            {gothe_mile?.dashboard?.title}
          </Typography>
          <Box className={classes.root}>
            <Grid container>
              <Grid item size={{ xs: 12 }}>
                {!loader && myOrder?.length === 0 ? (
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    {gothe_mile?.dashboard?.noSubscription}
                  </Typography>
                ) : (
                  <>
                    {myOrder?.map((item, id) => {
                      return (
                        <>
                          {item?.productForm &&
                            item?.productForm?.map((list, index) => {
                              return (
                                <>
                                  <Accordion
                                    key={index}
                                    expanded={
                                      expanded === `panel ${id} ${index}`
                                    }
                                    disableGutters
                                    elevation={0}
                                    // onChange={handleChange(
                                    //   `panel ${id} ${index}`,
                                    //   item,
                                    //   list
                                    // )}
                                  >
                                    <AccordionSummary
                                      aria-controls="panel1d-content"
                                      id="panel1d-header"
                                    >
                                      <Dashboard_summery
                                        product={list}
                                        dashboardData={item}
                                        id={id + 1}
                                        myOrderId={item?._id}
                                        idData={id}
                                        index={index}
                                        isloader={(data) => setLoader(data)}
                                        handleAcChange={(
                                          itemId,
                                          iteamIndex
                                        ) => {
                                          handleChange(
                                            `panel ${itemId} ${iteamIndex}`
                                          );
                                        }}
                                      />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Dashboard_details
                                        auth={auth}
                                        product={list}
                                        dashboardData={item}
                                        panelVal={`panel ${id} ${index}`}
                                        expandedVal={expanded}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Dashboard;
